export const mainMenu = {
    displayMenu: {
        default: {
            level0: [
                'onlyModels',
                {
                    'name': 'parameter',
                    'children': ['param-child']
                },
                {
                    name: 'mentions-legales',
                    class: ['menu-break'],
                },
                'cgurgpd',
                'politique-confidentialite'
            ],
        },
        learner: {
            level0: [
                'contact',
                'cgurgpd',
                {
                    name: 'mentions-legales',
                    class: ['menu-break'],
                },
                'politique-confidentialite'
            ],
        }
    },
};
