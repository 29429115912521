export const accountManagement = {
    canEditAvatar: [
        // nobody can edit avatar after create account
    ],
    allowedMaxSize: 1,
    displayDialogMinorAdult: false,
    displayFilters: false,
    fields: {
        learner: [
            'label|required',
            'email|required',
            'password|required',
            'passwordConfirm|required',
        ],
        default: [
            'label|required',
            'email|required',
            'password|required',
            'you_are|required',
            'newsletter',
            'region|required',
        ], // label = pseudo
    },
    floatLabelControl: 'always', // 'always' | 'never' | 'auto'
    selfSignup: true,
    passwordPolicy: {
        regex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
    },
    youAreValues: ['teacher', 'learner', 'parent'],
    validateEmailStrategyActivated: true,
    validateEmailStrategyMode: 'page',
    showJoinSchool: false, // part of code to join or leave a school
    editableFields: {
        trainer: ['label', 'email', 'password', 'you_are', 'picture'],
        default: ['label', 'email', 'password', 'you_are', 'picture', 'level'],
    },
    // si le select educationalLevel est caché dans le formulaire d'inscription car l'utilisateur indique qu'il est un 'teacher'
    isEducationalLevelSelectionHiddenIfUserIsTeacher: true,
};
