export const groupsManagement = {
    accessGroupsManager: ['director', 'manager', 'administrator'],
    accessGroupsTrainer: ['trainer', 'director', 'administrator'],
    authorizedRoles: ['learner', 'trainer', 'director', 'manager', 'administrator'],
    canBeDelete: {
        workgroup: true,
        institution: false,
        learner: true,
        learners: false,
    },
    canBeJoined: {
        group: true
    },
    collapsibleFields: ['school', 'archived', 'button-video-help', 'nickname', 'workgroupAssociate', 'groupAssociate'],
    displayAssignButtonInGroupList: true,
    displayTrainerLink: ['manager', 'administrator'],
    displayedFiltersLearners: {
        default: []
    },
    displayedFiltersTrainers: {
        default: []
    },
    displayedFiltersGroups: {
        default: []
    },
    displayedFiltersWorkGroups: {
        default: []
    },
    displayedFiltersInstitutions: {
        default: []
    },
    displayHeader: false,
    displayNews: {
        default: [],
        trainer: []
    },
    group: {
        columns: {
            default: []
        }
    },
    workgroupColumns: {
        default: []
    },
    groupType: 2,
    // id of the defi in list activity type
    idDefiTaxonomy: '5427',
    // ex :['5445'] use because somme activity in mathia must be hide in progress and not in activity tab
    idActivityToHideInProgressTab: [],
    institutionGroupType: 52,
    // when teacher create student account is password require
    isPasswordRequired: true,
    learner: {
        columns: {
            default: ['pseudo', 'buttons']
        },
        createFields: {default: ['avatar', 'nickname', 'password']},
        editFields: {default: ['id', 'avatar', 'nickname', 'password']}, // do not remove 'id' because it's needed to update data
        joinFields: {default: []},
    },
    noPasswordRegistration: true,
    levelsWidget: 'select',
    primaryColors: ['#C5629D', '#FF9EA1', '#EEDA78', '#6FF2C5', '#6DE6E2', '#305792'],
    // show main button to add class group or user that open modal dialog if false it's inline add
    showGlobalAddButton: false,
    workgroupType: 3,
    importInClasses: false,
    // set by default the teacher value when adding a new learner
    setTeacherRegionWhenAddNewLearner: true,
    forbiddenPaths: {
        default: [/^\/groups/],
        trainer: [],
        director: [],
    },
    displayIconInMenu: true
};
