export const cards = {
    cardDisplay: {
        showButtonThemeLesson: false,
        showButtonBookmarks: {
            themes: false,
            lesson: false,
            model: false,
            community: false,
            image: false,
            video: false,
            videoUrl: false,
            audio: false,
            document: false,
            url: false,
        },
        isTextButton: true,
    },
    cardDefaultHeader: '/assets/marqueblanche/images/backgrounds/header.png',
    cardFields: {
        default: {
            lesson: ['keywords', 'title', 'menu', 'image', 'description', 'activitiesCount', 'difficulty', 'assignation_type'],
            model: ['keywords', 'title', 'menu', 'image', 'description', 'activitiesCount', 'difficulty', 'assignation_type'],
            community: ['title', 'menu', 'image', 'description', 'activitiesCount', 'difficulty', 'assignation_type'],
            image: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            video: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            videoUrl: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            audio: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            document: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
            url: ['title', 'titleIcon', 'menu', 'image', 'skills', 'difficulty'],
        }
    },
    cardPlayOpenMenu: false,
    menuLinks: {
        lesson: {
            default: []
        },
        model: {
            default: []
        }
    },
    roleForbiddenToDownloadPdfLesson: ['learner'],
    isPdfMustBeOpenedDirectly: true,
};
