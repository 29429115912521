export const activities = {
    accessMatrix: {
        activitiesListing: {
            view: ['trainer', 'manager', 'administrator'],
        },
        lessonsListing: {
            view: ['learner', 'trainer', 'manager', 'administrator'],
        }
    },
    actionButtonsInMultiMatrix: {
        default: ['previousActivityInMulti', 'nextActivityInMulti']
    },
    activitiesDisplayedColumns: ['title', 'educationalLevel', 'chapters', 'action'],
    autoCorrection: true,
    buttons: {
        player: {
            auto: [
                {
                    title: 'generic.next',
                    type: 'next',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        MULTI: {
                            display: {
                                case: 'force',
                                value: true
                            },
                            disable: {
                                case: 'disableWithDelay',
                                value: 3000
                            }
                        },
                        awareness: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                        recording: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                        Tool: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        VF: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        QCMU: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                    },
                },
                {
                    title: 'generic.validate',
                    type: 'validate',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        CRT: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        RB: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        VF: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        QCMU: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                    },
                },
            ],
            preview: [
                {
                    title: 'generic.next',
                    type: 'next',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        MULTI: {
                            display: {
                                case: 'force',
                                value: true
                            },
                            disable: {
                                case: 'disableWithDelay',
                                value: 3000
                            }
                        },
                        Tool: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        EXT: {
                            display: {
                                case: 'force',
                                value: false
                            }
                        },
                        awareness: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                        recording: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                    },
                },
                {
                    title: 'generic.validate',
                    type: 'validate',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        CRT: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        RB: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        VF: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        QCMU: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                    },
                },
            ],
            training: [
                {
                    title: 'generic.next',
                    type: 'next',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        MULTI: {
                            display: {
                                case: 'force',
                                value: true
                            },
                            disable: {
                                case: 'disableWithDelay',
                                value: 3000
                            },
                        },
                        Tool: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        EXT: {
                            display: {
                                case: 'force',
                                value: false
                            }
                        },
                        awareness: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                        recording: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                    },
                },
                {
                    title: 'generic.validate',
                    type: 'validate',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        CRT: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        RB: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        VF: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        QCMU: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                    },
                },
            ],
            homework: [
                {
                    title: 'generic.next',
                    type: 'next',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        MULTI: {
                            display: {
                                case: 'force',
                                value: true
                            },
                            disable: {
                                case: 'disableWithDelay',
                                value: 3000
                            },
                        },
                        Tool: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        EXT: {
                            display: {
                                case: 'force',
                                value: false
                            }
                        },
                        awareness: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                        recording: {
                            display: {
                                case: 'force',
                                value: true,
                            },
                        },
                    },
                },
                {
                    title: 'generic.validate',
                    type: 'validate',
                    classCss: ['btn-option', 'btn-next'],
                    display: false,
                    options: {
                        CRT: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        RB: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        VF: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                        QCMU: {
                            display: {
                                case: 'force',
                                value: true
                            }
                        },
                    },
                },
            ],
        },
    },
    cardFieldsForPreview: [ 'chapters', 'description'],
    cardLayout: 'card-split-media-info',
    displayBtnRetryInSummarySubLesson: false,
    displayDetailsBlockInSummarySubLesson: false,
    displayGradeBlockInSummarySubLesson: true,
    displayLearnerInfo: false,
    displayQuizBtnInCard: true,
    filtersEditorActivitiesList: ['title', 'educationnalLevel', 'chapters'],
    hiddenActivityPreview: false,
    hideAddButtonLessonList: true,
    hideUserActionButtons: true,
    iframeParams: [],// required params name for partner through iframe url
    isAutoAssignmentForced: true,
    isListFormatStepper: true, // stepper is in list format (mat-select)
    isOnboardingEnabled: true,
    isOnlyModelLesson: true, // should be called by example : enableSelectionByTab for lesson list component
    isPercentileDisplayAllowed: false,
    isStepperAllowedAllStep: true,
    lessonDialogRequiredFields:  {
        default: ['title', 'educationalLevel'],
    },
    lessonStep: null,
    menu: {
        models: true
    },
    openDialogInfoAfterDuplicateLesson: {
        manager: false,
        default: true
    },
    progress: false,
    searchFields: {
        default: {
            default: []
        },
    },
    showAddLessonButtonCard: true,
    showLessonTitle: false,
    stepper: null,
    hideTopBarForSpecificActivitiesTypes: ['EXT'],
    typeActivitiesToSkip: [], // (lesson player) skip activity if activity's type is included,
    useSummaryPageInsteadOfRecap: true,
    setAnswerWithUserSave: false,
};
