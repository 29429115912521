export const authentication = {
    displayLoginLogo: true,
    enableSSO: false,
    floatLabelControl: 'always', // 'always' | 'never' | 'auto'
    firstConnexionRedirection: {
        default: '/',
    },
    activeChangePasswordStrategy: false,
    onlyLoginWithMail: false
};
