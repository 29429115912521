import {ModuleIconInterface} from "../module-icon.interface";

export class SvgIcon {
    [key: string]: string | ModuleIconInterface
}

export const ICONSACTIVITYMODULE: SvgIcon = {
    'app-activity-search-engine-panel__selected-count-label__icon': { icon_path: 'activities', icon_name: 'check' },
    'app-activity-search-engine-panel__validate-selection-button__icon': { icon_path: 'activities', icon_name: 'download' },
    'activity-sample__QCMU': { icon_path: 'activities', icon_name: 'text' },
    'activity-sample__icon-image': { icon_path: 'activities', icon_name: 'panorama' },
    'activity-sample__icon-audio': { icon_path: 'activities', icon_name: 'graphic_eq' },
    'activity-sample__icon-video': { icon_path: 'activities', icon_name: 'play' },
    'activity-sample__icon-text': { icon_path: 'activities', icon_name: 'text' },
    'activity-sample__icon-keyboard': { icon_path: 'activities', icon_name: 'keyboard' },
    'activity-sample__icon-undefined-type': { icon_path: 'activities', icon_name: 'graphic_eq' },
    'activity-sample__icon-pairing': { icon_path: 'activities', icon_name: 'attach_file' },
    'thumb_up': { icon_path: 'activities', icon_name: 'thumb_up' },
    'thumb_down': { icon_path: 'activities', icon_name: 'thumb_down' },
    'neo__icon-first-time': { icon_path: 'activities', icon_name: 'bright_stars' },
    'neo__icon-already-practiced': { icon_path: 'activities', icon_name: 'plus' },
    'neo__icon-searching': { icon_path: 'activities', icon_name: 'search' },
    'lesson-meta-form__input_icon': { icon_path: 'activities', icon_name: 'search' },
    'move_right': { icon_path: 'activities', icon_name: 'move_right' },
    'move_left': { icon_path: 'activities', icon_name: 'move_left' },
    'undo': { icon_path: 'activities', icon_name: 'undo' },
    'redo': { icon_path: 'activities', icon_name: 'redo' },
    'editor-panel__button-add-icon': { icon_path: 'activities', icon_name: 'folder_open' },
    'sound_off': { icon_path: 'activities', icon_name: 'sound_off' },
    'sound_on': { icon_path: 'activities', icon_name: 'sound_on' },
    'qcm__register__incorrect': { icon_path: 'activities', icon_name: 'false' },
    'qcm__register__not-correct': { icon_path: 'activities', icon_name: 'false' },
    'qcm__register__null': { icon_path: 'activities', icon_name: 'check' },
    'qcm__register__pristine': { icon_path: 'activities', icon_name: 'check' },
    'true': { icon_path: 'activities', icon_name: 'check' },
    'false': { icon_path: 'activities', icon_name: 'false' },
}